import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import './index.scss';

/*
  ImageRenderer displays image with url or source
  it checks if the source exist with img tag first
  if it exists onLoad is called, if not onError is called
  and those properties switch img tag to real purposing element
*/

export default function ImageRenderer({
  alt,
  url,
  width,
  height,
  className,
  defaultComponent,
  circle,
}) {
  const injectingClassName = Array.isArray(className) ? className : [className];
  injectingClassName.unshift('sendbird-image-renderer');
  const [realElement, setRealElement] = useState(null);
  useEffect(() => {
    setRealElement(null);
  }, [url]);
  return (
    realElement || (
      <img
        className={`sendbird-image-renderer--hidden-placeholder ${injectingClassName.join(' ')}`}
        src={url}
        alt={alt}
        onError={() => {
          setRealElement(typeof defaultComponent === 'function' ? defaultComponent() : defaultComponent);
        }}
        onLoad={() => {
          setRealElement((
            <div
              className={injectingClassName.join(' ')}
              style={{
                width,
                height,
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                backgroundImage: `url(${url})`,
                borderRadius: circle ? '50%' : null,
              }}
            />
          ));
        }}
      />
    )
  );
}

ImageRenderer.propTypes = {
  alt: PropTypes.string,
  url: PropTypes.string.isRequired,
  width: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  height: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  className: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.string,
  ]),
  defaultComponent: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.func,
  ]),
  circle: PropTypes.bool,
};
ImageRenderer.defaultProps = {
  alt: '',
  className: '',
  circle: false,
  defaultComponent: null,
  width: null,
  height: null,
};
