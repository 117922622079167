import { useEffect } from 'react';

import * as messageActionTypes from '../dux/actionTypes';
import * as utils from '../utils';

function useInitialMessagesFetch({ currentGroupChannel, userFilledMessageListQuery }, {
  sdk,
  logger,
  messagesDispatcher,
}) {
  const channelUrl = currentGroupChannel && currentGroupChannel.url;
  useEffect(() => {
    logger.info('Channel useInitialMessagesFetch: Setup started', currentGroupChannel);

    messagesDispatcher({
      type: messageActionTypes.RESET_MESSAGES,
    });

    if (sdk && sdk.MessageListParams
      && currentGroupChannel && currentGroupChannel.getMessagesByMessageId) {
      const messageListParams = new sdk.MessageListParams();
      messageListParams.prevResultSize = 30;
      messageListParams.isInclusive = true;
      messageListParams.includeReplies = false;
      messageListParams.includeReaction = true;
      if (userFilledMessageListQuery) {
        Object.keys(userFilledMessageListQuery).forEach((key) => {
          messageListParams[key] = userFilledMessageListQuery[key];
        });
      }
      logger.info('Channel: Fetching messages', { currentGroupChannel, userFilledMessageListQuery });
      messagesDispatcher({
        type: messageActionTypes.GET_PREV_MESSAGES_START,
      });

      currentGroupChannel.getMessagesByTimestamp(
        new Date().getTime(),
        messageListParams,
      )
        .then((messages) => {
          const hasMore = (messages && messages.length > 0);
          const lastMessageTimeStamp = hasMore
            ? messages[0].createdAt
            : null;
          messagesDispatcher({
            type: messageActionTypes.GET_PREV_MESSAGES_SUCESS,
            payload: {
              messages,
              hasMore,
              lastMessageTimeStamp,
              currentGroupChannel,
            },
          });
        })
        .catch((error) => {
          logger.error('Channel: Fetching messages failed', error);
          messagesDispatcher({
            type: messageActionTypes.GET_PREV_MESSAGES_SUCESS,
            payload: {
              messages: [],
              hasMore: false,
              lastMessageTimeStamp: 0,
              currentGroupChannel,
            },
          });
        })
        .finally(() => {
          currentGroupChannel.markAsRead();
          setTimeout(() => utils.scrollIntoLast());
        });
    }
  }, [channelUrl, userFilledMessageListQuery]);
}

export default useInitialMessagesFetch;
