import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import './index.scss';
import Icon, { IconTypes, IconColors } from '../Icon';
import Loader from '../Loader';
import { LocalizationContext } from '../../lib/LocalizationContext';
import Label, { LabelColors, LabelTypography } from '../Label';
import _PlaceHolderTypes from './type';

export const PlaceHolderTypes = _PlaceHolderTypes;

export default function PlaceHolder({
  className,
  type,
  retryToConnect,
}) {
  const { stringSet } = useContext(LocalizationContext);
  const injectingClassName = (Array.isArray(className)) ? className : [className];
  return (
    <div className={[...injectingClassName, 'sendbird-place-holder'].join(' ')}>
      {
        type === PlaceHolderTypes.LOADING && (
          <Loader width="48px" height="48px">
            <Icon type={IconTypes.SPINNER_LARGE} width="48px" height="48px" />
          </Loader>
        )
      }
      {
        (
          type === PlaceHolderTypes.NO_CHANNELS
          || type === PlaceHolderTypes.NO_MESSAGES
          || type === PlaceHolderTypes.WRONG
        ) && (
          <div className="sendbird-place-holder__body">
            {
              type === PlaceHolderTypes.NO_CHANNELS && (
                <Icon
                  className="sendbird-place-holder__body__icon"
                  type={IconTypes.CHAT}
                  fillColor={IconColors.ON_BACKGROUND_3}
                  width="64px"
                  height="64px"
                />
              )
            }
            {
              type === PlaceHolderTypes.WRONG && (
                <Icon
                  className="sendbird-place-holder__body__icon"
                  type={IconTypes.ERROR}
                  fillColor={IconColors.ON_BACKGROUND_3}
                  width="64px"
                  height="64px"
                />
              )
            }
            {
              type === PlaceHolderTypes.NO_MESSAGES && (
                <Icon
                  className="sendbird-place-holder__body__icon"
                  type={IconTypes.MESSAGE}
                  fillColor={IconColors.ON_BACKGROUND_3}
                  width="64px"
                  height="64px"
                />
              )
            }
            <Label className="sendbird-place-holder__body__text" type={LabelTypography.BODY_1} color={LabelColors.ONBACKGROUND_2}>
              {
                type === PlaceHolderTypes.NO_CHANNELS && (
                  stringSet.PLACE_HOLDER__NO_CHANNEL
                )
              }
              {
                type === PlaceHolderTypes.WRONG && (
                  stringSet.PLACE_HOLDER__WRONG
                )
              }
              {
                type === PlaceHolderTypes.NO_MESSAGES && (
                  stringSet.PLACE_HOLDER__NO_MESSAGES
                )
              }
            </Label>
            {
              retryToConnect && (
                <div
                  className="sendbird-place-holder__body__reconnect"
                  role="button"
                  tabIndex={0}
                  onClick={retryToConnect}
                  onKeyPress={retryToConnect}
                >
                  <Icon
                    className="sendbird-place-holder__body__reconnect__icon"
                    type={IconTypes.REFRESH}
                    fillColor={IconColors.PRIMARY}
                    width="20px"
                    height="20px"
                  />
                  <Label className="sendbird-place-holder__body__reconnect__text" type={LabelTypography.BUTTON_1} color={LabelColors.PRIMARY}>
                    {stringSet.PLACE_HOLDER__RETRY_TO_CONNECT}
                  </Label>
                </div>
              )
            }
          </div>
        )
      }
    </div>
  );
}

PlaceHolder.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  type: PropTypes.oneOfType([
    PropTypes.oneOf(Object.keys(PlaceHolderTypes)),
    PropTypes.string,
  ]).isRequired,
  retryToConnect: PropTypes.func,
};

PlaceHolder.defaultProps = {
  className: '',
  retryToConnect: null,
};
