import Sendbird from 'sendbird';
import { EveryMessage } from '../../../index';

export interface State {
  allMessages: EveryMessage[];
  loading: boolean;
  initialized: boolean;
  currentOpenChannel: Sendbird.OpenChannel;
  isInvalid: boolean;
  hasMore: boolean;
  lastMessageTimestamp: number;
  frozen: boolean;
  operators: Sendbird.User[];
  participants: Sendbird.User[];
  bannedParticipantIds: (string|number)[];
  mutedParticipantIds: (string|number)[];
}

const initialState: State = {
  allMessages: [],
  loading: false,
  initialized: false,
  currentOpenChannel: null,
  isInvalid: false,
  hasMore: false,
  lastMessageTimestamp: 0,
  frozen: false,
  operators: [],
  participants: [],
  bannedParticipantIds: [],
  mutedParticipantIds: [],
};

export default initialState;
