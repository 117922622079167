import React, { ReactElement } from 'react';
import './index.scss';

import Avatar from '../Avatar';
import Icon, { IconColors, IconTypes } from '../Icon';
import IconButton from '../IconButton';
import Label, {
  LabelTypography,
  LabelColors,
  LabelStringSet,
} from '../Label';

const COMPONENT_CLASS_NAME = 'sendbird-openchannel-conversation-header';

interface Props {
  coverImage?: string;
  title?: string;
  subTitle?: string;
  amIOperator?: boolean;
  onActionClick?(): void;
}

export default function OpenchannelConversationHeader({
  coverImage,
  title = LabelStringSet.NO_TITLE,
  subTitle = LabelStringSet.NO_TITLE,
  amIOperator,
  onActionClick,
}: Props): ReactElement {
  return (
    <div className={COMPONENT_CLASS_NAME}>
      <div className={`${COMPONENT_CLASS_NAME}__left`}>
        {
          coverImage ? (
            <Avatar
              className={`${COMPONENT_CLASS_NAME}__left__cover-image`}
              src={coverImage}
              alt="channel cover image"
              width="32px"
              height="32px"
            />
          ) : (
              <Icon
                className={`${COMPONENT_CLASS_NAME}__left__cover-image--icon`}
                width="32px"
                height="32px"
                type={IconTypes.AVATAR_NO_IMAGE}
                fillColor={IconColors.BACKGROUND_3}
              />
            )
        }
        <Label
          className={`${COMPONENT_CLASS_NAME}__left__title`}
          type={LabelTypography.H_2}
          color={LabelColors.ONBACKGROUND_1}
        >
          {title}
        </Label>
        <Label
          className={`${COMPONENT_CLASS_NAME}__left__sub-title`}
          type={LabelTypography.BODY_2}
          color={LabelColors.ONBACKGROUND_2}
        >
          {subTitle}
        </Label>
      </div>
      <div className={`${COMPONENT_CLASS_NAME}__right`}>
        <IconButton
          className={`${COMPONENT_CLASS_NAME}__right__trigger`}
          width="32px"
          height="32px"
          onClick={onActionClick}
        >
          <Icon
            type={(
              amIOperator
              ? IconTypes.INFO
              : IconTypes.MEMBERS
            )}
            fillColor={IconColors.PRIMARY}
            width="24px"
            height="24px"
          />
        </IconButton>
      </div>
    </div>
  );
}
