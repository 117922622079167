import React from 'react';
import PropTypes from 'prop-types';

import Label, { LabelTypography, LabelColors } from '../Label';
import { changeColorToClassName } from '../Label/utils';
import './index.scss';

const LINK_LABEL = 'sendbird-link-label';
const http = /https?:\/\//;

export default function LinkLabel({
  className,
  src,
  type,
  color,
  children,
}) {
  const injectingClassName = Array.isArray(className) ? className : [className];
  if (color) {
    injectingClassName.push(changeColorToClassName(color));
  }
  const url = (http.test(src)) ? src : `http://${src}`;

  return (
    <a
      className={`${LINK_LABEL} ${injectingClassName.join(' ')}`}
      href={url}
      target="_blank"
      rel="noopener noreferrer"
    >
      <Label type={type} color={color} className={`${LINK_LABEL}__label`}>
        {children}
      </Label>
    </a>
  );
}

LinkLabel.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.arrayOf(PropTypes.element),
  ]).isRequired,
  src: PropTypes.string.isRequired,
  type: PropTypes.oneOf(Object.keys(LabelTypography)).isRequired,
  color: PropTypes.oneOf(Object.keys(LabelColors)).isRequired,
};

LinkLabel.defaultProps = {
  className: '',
};

export const LinkLabelTypography = LabelTypography;
export const LinkLabelColors = LabelColors;
