import React from 'react';
import PropTypes from 'prop-types';

import './index.scss';
import Type from './type';

import IconAdd from '../../svgs/icon-add.svg';
import IconAttach from '../../svgs/icon-attach.svg';
import IconArrowLeft from '../../svgs/icon-arrow-left.svg';
import IconAvatarDark from '../../svgs/icon-avatar-dark.svg';
import IconAvatarLight from '../../svgs/icon-avatar-light.svg';
import IconAvatarNoImage from '../../svgs/avatar-no-image-light.svg';
import BroadcastLargeDark from '../../svgs/icon-broadcast-lrg-dark.svg';
import BroadcastLargeLight from '../../svgs/icon-broadcast-lrg-light.svg';
import BroadcastDark from '../../svgs/icon-broadcast-dark.svg';
import BroadcastLight from '../../svgs/icon-broadcast-light.svg';
import Broadcast from '../../svgs/icon-broadcast.svg';
import FrozenDark from '../../svgs/frozen-dark.svg';
import FrozenLight from '../../svgs/frozen-light.svg';
import IconCamera from '../../svgs/icon-camera.svg';
import IconMore from '../../svgs/icon-more.svg';
import IconMute from '../../svgs/icon-mute.svg';
import IconNoThumbnail from '../../svgs/icon-no-thumbnail.svg';
import IconCheckbox from '../../svgs/icon-checkbox.svg';
import IconCheckboxOff from '../../svgs/icon-checkbox-off.svg';
import IconClose from '../../svgs/icon-close.svg';
import IconCopy from '../../svgs/icon-copy.svg';
import IconChat from '../../svgs/icon-chat.svg';
import IconSupergroup from '../../svgs/icon-supergroup.svg';
import IconCreate from '../../svgs/icon-create.svg';
import IconDelete from '../../svgs/icon-delete.svg';
import IconDummy from '../../svgs/icon-dummy.svg';
import IconEdit from '../../svgs/icon-edit.svg';
import IconError from '../../svgs/icon-error.svg';
import IconErrorFilled from '../../svgs/icon-error-filled.svg';
import IconEmojiFailed from '../../svgs/emoji-fail.svg';
import IconEmojiReactionsAdd from '../../svgs/icon-reactions-add.svg';
import IconEmojiReactionsAddGray from '../../svgs/icon-emoji-reactions-add.svg';
import IconDelivered from '../../svgs/icon-delivered.svg';
import IconDisconnected from '../../svgs/icon-disconnected.svg';
import IconDocument from '../../svgs/icon-document.svg';
import IconDownload from '../../svgs/icon-download.svg';
import IconFileAudio from '../../svgs/icon-file-audio.svg';
import IconFileDocument from '../../svgs/icon-file-document.svg';
import IconGif from '../../svgs/icon-gif.svg';
import IconInfo from '../../svgs/icon-info.svg';
import IconLeave from '../../svgs/icon-leave.svg';
import IconMembers from '../../svgs/icon-members.svg';
import IconMessage from '../../svgs/icon-message.svg';
import IconNotifications from '../../svgs/icon-notifications.svg';
import IconPhoto from '../../svgs/icon-photo.svg';
import IconPlay from '../../svgs/icon-play.svg';
import IconPlus from '../../svgs/icon-plus.svg';
import IconRead from '../../svgs/icon-read.svg';
import IconRefresh from '../../svgs/icon-refresh.svg';
import IconSend from '../../svgs/icon-send.svg';
import IconSent from '../../svgs/icon-sent.svg';
import IconShevron from '../../svgs/icon-shevron.svg';
import IconShevronDown from '../../svgs/icon-shevron-down.svg';
import IconSpinner from '../../svgs/icon-spinner-small.svg';
import IconSpinnerLarge from '../../svgs/icon-spinner-large.svg';
import IconUser from '../../svgs/icon-user.svg';
import IconOperator from '../../svgs/icon-operator.svg';
import IconBanned from '../../svgs/icon-banned.svg';
import IconMuted from '../../svgs/icon-muted.svg';
import IconMutedFg from '../../svgs/icon-muted-foreground.svg';
import IconFreeze from '../../svgs/icon-freeze.svg';
import ToggleOn from '../../svgs/icon-toggleon.svg';
import ToggleOff from '../../svgs/icon-toggleoff.svg';

const Colors = {
  DEFAULT: 'DEFAULT',
  PRIMARY: 'PRIMARY',
  SECONDARY: 'SECONDARY',
  CONTENT: 'CONTENT',
  CONTENT_INVERSE: 'CONTENT_INVERSE',
  WHITE: 'WHITE',
  SENT: 'SENT',
  READ: 'READ',
  ON_BACKGROUND_2: 'ON_BACKGROUND_2',
  ON_BACKGROUND_3: 'ON_BACKGROUND_3',
  BACKGROUND_3: 'BACKGROUND_3',
};

function changeColorToClassName(color) {
  switch (color) {
    case Colors.PRIMARY: return 'sendbird-color--primary';
    case Colors.SECONDARY: return 'sendbird-color--secondary';
    case Colors.CONTENT: return 'sendbird-color--content';
    case Colors.CONTENT_INVERSE: return 'sendbird-color--content-inverse';
    case Colors.WHITE: return 'sendbird-color--white';
    case Colors.SENT: return 'sendbird-color--sent';
    case Colors.READ: return 'sendbird-color--read';
    case Colors.ON_BACKGROUND_2: return 'sendbird-color--on-background-2';
    case Colors.ON_BACKGROUND_3: return 'sendbird-color--on-background-3';
    case Colors.BACKGROUND_3: return 'sendbird-color--background-3';
    default: return '';
  }
}

function changeTypeToIconComponent(type) {
  switch (type) {
    case Type.ADD: return <IconAdd />;
    case Type.ARROW_LEFT: return <IconArrowLeft />;
    case Type.CAMERA: return <IconCamera />;
    case Type.ATTACH: return <IconAttach />;
    case Type.AVATAR_DARK: return <IconAvatarDark />;
    case Type.AVATAR_LIGHT: return <IconAvatarLight />;
    case Type.AVATAR_NO_IMAGE: return <IconAvatarNoImage />;
    case Type.BROADCAST_LARGE_DARK: return <BroadcastLargeDark />;
    case Type.BROADCAST_LARGE_LIGHT: return <BroadcastLargeLight />;
    case Type.BROADCAST_DARK: return <BroadcastDark />;
    case Type.BROADCAST_LIGHT: return <BroadcastLight />;
    case Type.BROADCAST: return <Broadcast />;
    case Type.FROZEN_DARK: return <FrozenDark />;
    case Type.FROZEN_LIGHT: return <FrozenLight />;
    case Type.MORE: return <IconMore />;
    case Type.MUTE: return <IconMute />;
    case Type.NO_THUMBNAIL: return <IconNoThumbnail />;
    case Type.CHECKBOX: return <IconCheckbox />;
    case Type.CHECKBOX_OFF: return <IconCheckboxOff />;
    case Type.CLOSE: return <IconClose />;
    case Type.COPY: return <IconCopy />;
    case Type.CHAT: return <IconChat />;
    case Type.SUPER_GROUP: return <IconSupergroup />;
    case Type.CREATE: return <IconCreate />;
    case Type.DELETE: return <IconDelete />;
    case Type.DISCONNECTED: return <IconDisconnected />;
    case Type.DUMMY: return <IconDummy />;
    case Type.EDIT: return <IconEdit />;
    case Type.ERROR: return <IconError />;
    case Type.ERROR_FILLED: return <IconErrorFilled />;
    case Type.EMOJI_FAILED: return <IconEmojiFailed />;
    case Type.EMOJI_REACTIONS_ADD: return <IconEmojiReactionsAdd />;
    case Type.EMOJI_REACTIONS_ADD_GRAY: return <IconEmojiReactionsAddGray />;
    case Type.DELIVERED: return <IconDelivered />;
    case Type.DOCUMENT: return <IconDocument />;
    case Type.DOWNLOAD: return <IconDownload />;
    case Type.FILE_AUDIO: return <IconFileAudio />;
    case Type.FILE_DOCUMENT: return <IconFileDocument />;
    case Type.GIF: return <IconGif />;
    case Type.INFO: return <IconInfo />;
    case Type.LEAVE: return <IconLeave />;
    case Type.MEMBERS: return <IconMembers />;
    case Type.MESSAGE: return <IconMessage />;
    case Type.NOTIFICATIONS: return <IconNotifications />;
    case Type.PHOTO: return <IconPhoto />;
    case Type.PLAY: return <IconPlay />;
    case Type.PLUS: return <IconPlus />;
    case Type.READ: return <IconRead />;
    case Type.REFRESH: return <IconRefresh />;
    case Type.SEND: return <IconSend />;
    case Type.SENT: return <IconSent />;
    case Type.SHEVRON: return <IconShevron />;
    case Type.SHEVRON_DOWN: return <IconShevronDown />;
    case Type.SPINNER: return <IconSpinner />;
    case Type.SPINNER_LARGE: return <IconSpinnerLarge />;
    case Type.USER: return <IconUser />;
    case Type.ICON_OPERATOR: return <IconOperator />;
    case Type.ICON_BANNED: return <IconBanned />;
    case Type.ICON_MUTED: return <IconMuted />;
    case Type.ICON_MUTED_FG: return <IconMutedFg />;
    case Type.ICON_FREEZE: return <IconFreeze />;
    case Type.TOGGLE_ON: return <ToggleOn />;
    case Type.TOGGLE_OFF: return <ToggleOff />;
    default: return null;
  }
}

export default function Icon({
  type,
  width,
  height,
  children,
  className,
  onClick,
  fillColor,
}) {
  const iconStyle = {
    width: typeof width === 'string' ? width : `${width}px`,
    minWidth: typeof width === 'string' ? width : `${width}px`,
    height: typeof height === 'string' ? height : `${height}px`,
    minHeight: typeof height === 'string' ? height : `${height}px`,
  };
  const injectingClassName = (Array.isArray(className)) ? className : [className];
  return (
    <div
      onClick={onClick}
      onKeyDown={onClick}
      role="button"
      tabIndex="0"
      className={[
        ...injectingClassName,
        'sendbird-icon',
        changeColorToClassName(fillColor),
      ].join(' ')}
      style={iconStyle}
    >
      {
        children || changeTypeToIconComponent(type)
      }
    </div>
  );
}

Icon.propTypes = {
  type: PropTypes.oneOfType([
    PropTypes.oneOf(Object.keys(Type)),
    PropTypes.string,
  ]).isRequired,
  width: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  height: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  onClick: PropTypes.func,
  children: PropTypes.element,
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  fillColor: PropTypes.oneOf(Object.keys(Colors)),
};

Icon.defaultProps = {
  onClick: () => { },
  width: 26,
  height: 26,
  children: null,
  className: '',
  fillColor: Colors.DEFAULT,
};

export const IconTypes = Type;
export const IconColors = Colors;
