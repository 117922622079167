import React, { useMemo } from 'react';

import Avatar from '../Avatar/index';
import Icon, { IconTypes } from '../Icon';

import * as utils from './utils';

interface Props {
  channel: SendBird.GroupChannel;
  userId: string;
  theme: string;
  height?: number,
  width?: number,
}

function ChannelAvatar({
  channel,
  userId,
  theme,
  height = 56,
  width = 56,
}: Props): JSX.Element{
  const { isBroadcast } = channel;
  const memoizedAvatar = useMemo(() => (
    isBroadcast
      ? (
        utils.useDefaultAvatar(channel)
          ? (
            theme === 'dark'
              ? (
                <Icon
                  type={IconTypes.BROADCAST_LARGE_DARK}
                  width={width}
                  height={height}
                  className="sendbird-chat-header__default--broadcast-channel-dark"
                />
                )
              : (
                <Icon
                  type={IconTypes.BROADCAST_LARGE_LIGHT}
                  width={width}
                  height={height}
                  className="sendbird-chat-header__default--broadcast-channel-light"
                />
              )
          )
          : (
            <Avatar
              className="sendbird-chat-header__avatar--broadcast-channel"
              src={utils.getChannelAvatarSource(channel, userId)}
              width={width}
              height={height}
              alt={channel.name}
            />
          )
      )
      : (
        <Avatar
          className="sendbird-chat-header__avatar--group-channel"
          src={utils.getChannelAvatarSource(channel, userId)}
          width={`${width}px`}
          height={`${height}px`}
          alt={channel.name}
        />
      )
  ),[channel.members, channel.coverUrl, theme]);
  return (
    <>{ memoizedAvatar }</>
  );
}

export default ChannelAvatar;
